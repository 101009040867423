.maps {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #DEDEDE;
}

.container-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 10%;
}

.container-colum, .container-colum-iframe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.container-colum-iframe iframe {
    width: 100%;
}

.maps i {
    background: gold;
    border-radius: 50%;
    padding: 15px;
    margin-bottom: 20px;
}

.maps .boxInfo {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;

    font-family: 'DM Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.10px;
    text-align: center;
}

.maps p {
    margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .container-colum-iframe {
        display: none;
    }
    .container-colum {
        width: 100%;
        padding-bottom: 150px;
    }
}