footer {
    background: #EEEEEE;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container-footer {
    display: flex;
    flex-direction: column;
    justify-self: start;
    align-items: center;
    width: 80%;
    margin: 0 10%;
    margin: 10px 0;
    padding: 10px 0;
}

.container-row, .container-row-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.container-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 550px;
}

.container-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 233, 37, 0.92);
    box-shadow: 7px 10px 12px #E5E5E5;
    border-radius: 0 45px 45px 0;
    width: 100%;
    height: 150px;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleFooter {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #252B42;
}

/*MESSAGE*/
.message div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.message-pregunta {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 0 5px;
}

.message-accion {
    font-size: 20px;
    text-align: center;
    padding: 0 5px;
}

/*FORM*/

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    padding: 20px;
    height: 100%;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
}

input {
    margin: 5px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0.5em 2em;
    background: #F5F5F5;
    border: 1px solid #E8E8E8;
    border-radius: 25px;
}

textarea {
    margin: 5px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0.5em 2em;
    background: #F5F5F5;
    border: 1px solid #E8E8E8;
    border-radius: 25px;
}

textarea {
    min-height: 200px;
}

input[type="submit"] {
    background: #FFE925;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #181818;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 60%;
}

.form-row a {
    display: flex;
    justify-content: center;
}

input[type="enviar"] {
    background: #FFE925;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #181818;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 60%;
    text-align: center;
}

input[type="submit"]:hover {
    box-shadow: 3px 4px 7px 5px rgba(0, 0, 0, 0.15);
}
input[type="enviar"]:hover {
    box-shadow: 3px 4px 7px 5px rgba(0, 0, 0, 0.15);
}

form label {
    background: rgba(229, 229, 229, 0.17);
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 1em 2em;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
    color: rgba(24, 23, 29, 0.42);
    cursor: pointer;
    transition: all 0.2s ease;
}

form label:hover {
    box-shadow: 3px 4px 7px 5px rgba(0, 0, 0, 0.15);
}

/* label underline focus color */
.input-field input[type=text]:focus,
.input-field input[type=tel]:focus,
.input-field input[type=email]:focus,
.input-field textarea:focus {
    border-bottom: 1px solid #FFE925;
    box-shadow: 0 1px 0 0 #FFE925;
}

@media only screen and (max-width: 1000px) {
    .container-row-column {
        flex-direction: column;
    }
    .container-form {
        width: 100%;
    }
    .container-logo {
        display: none
    }
}

@media only screen and (max-width: 600px) {
    .form-row {
        flex-direction: column;
    }
        .container-form {
        height: 700px;
    }
}