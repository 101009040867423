.servicio-tableros {
    box-sizing: border-box;
    width: 271px;
    height: 271px;
    background: rgba(229, 229, 229, 0.21);
    border: 1px solid #FFE925;
    box-shadow: 5px 7px 13px rgba(0, 0, 0, 0.12);
    border-radius: 100%;
    padding: 15px;
    z-index: 10000;
}

.container-section-servicios-tablero {

}

.container-section-servicios-tablero-lineas {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: -10000;
    margin-left: 5vw;
    margin-top: -1.5vw;
}

.container-section-servicios-tablero-lineas img {
    z-index: -100000;
}

.container-section-servicios-tablero-lineas span {
    display: inline-block;
    font-size: 1.2vw;
}

.container-section-servicios-tablero-linea-1 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -12vw;
}

.container-section-servicios-tablero-linea-2 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -8vw;
}

.container-section-servicios-tablero-linea-3 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -6vw;
}

.container-section-servicios-tablero-linea-4 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -5vw;
    padding-bottom: 1.5vw;
}

.container-section-servicios-tablero-linea-5 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -4.5vw;
}

.container-section-servicios-tablero-linea-6 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -5vw;
}

.container-section-servicios-tablero-linea-7 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -6vw;
}

.container-section-servicios-tablero-linea-8 {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-left: -9vw;
}


.container-section-servicios-tablero-linea-1 span,
.container-section-servicios-tablero-linea-2 span,
.container-section-servicios-tablero-linea-3 span,
.container-section-servicios-tablero-linea-4 span {
    margin-top: -1.5vh;
    padding-left: .5vw;
}

.container-section-servicios-tablero-linea-5 span,
.container-section-servicios-tablero-linea-6 span,
.container-section-servicios-tablero-linea-7 span,
.container-section-servicios-tablero-linea-8 span {
    margin-top: 1.5vh;
    padding-left: .5vw;
}

.container-section-servicios-tablero-responsive {
    display: none;
}

.servicios-aux {
    display: none;
}
.servicios-aux-mobile { 
    display: none;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1000px) {
    .container-section-servicios-tablero-lineas {
        display: none;
    }
    .container-section-servicios-tablero-responsive {
        display: flex;
        background: #F9F9FA;
        box-shadow: -7px 7px 10px rgba(196, 196, 196, 0.36);
        border-radius: 20px;
        margin-bottom: 50px;
        padding-right: 10px;
        margin-right: -200%;
    }
    .container-section {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .servicios-aux {
        display: block;
    }
    .servicios-aux-mobile { 
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .container-section-servicios-tablero-lineas {
        display: none;
    }
    .container-section-servicios-tablero-responsive {
        display: flex;
        background: #F9F9FA;
        box-shadow: -7px 7px 10px rgba(196, 196, 196, 0.36);
        border-radius: 20px;
        margin-bottom: 50px;
        padding-right: 10px;
        margin-right: 0;
    }
    .container-section {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .servicios-aux {
        display: none;
    }
    .servicios-aux-mobile { 
        display: block;
    }
}

.lista-servicios-tablero {

}

.linea-servicios-tablero {
    font-size: 15px;
    text-align: left;
    padding: 1px 0;
}