.background-contactanos {
    position: absolute;
    height: 140vw;
    width: 100%;
    top: 0;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-image: url("../../imagenes/fondo-encabezado-degrade-inferior.webp");
    background-position: center top;
    background-size: 100%;
    z-index: 0;
    margin-top: -5px;
}

.container-section-contactanos {
    margin-top: -21%;
    position: relative;
    top: 45vw;
    left: 7%;
    width: 70%;
    text-align: center;
    z-index: -100;
}

.container-section-contactanos h4 {
    font-size: 4vw;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0;
    margin: 0;
}

.container-section-contactanos p {
    font-size: 1.5vw;
    width: 80%;
    text-align: center;
    padding-left: 10%;
    font-weight: lighter;
}
.container-section-contactanos a {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
}

.buttom-contactanos {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 50px;
    width: 25%;
    margin-left: 37.5%;
    margin-top: 30px;
    background-color: #FFE925;
    border: 3px solid #FFE925;
    border-radius: 5px;
    z-index: 10000;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
}

.buttom-contactanos:hover {
    box-shadow: 3px 4px 7px 5px rgba(0, 0, 0, 0.15);
}


a {
    list-style: none;
    text-decoration: none;
    color: #181818;
}

.buttom-contactanos h3 {
    text-align: center;
    font-size: 1.2vw;
    width: 100%;
    list-style: none;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1000px) {

}
@media only screen and (max-width: 800px) {
    .background-contactanos {
        position: absolute;
        height: 140vw;
        width: 100%;
        top: 0;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-image: url("../../imagenes/fondo-encabezado-degrade-inferior.webp");
        background-position: center top;
        background-size: 150%;
    }
    .container-section-contactanos {
        margin-top: -11%;
        position: relative;
        top: 35vw;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: -100;
    }
    .container-section-contactanos h4 {
        font-size: 45px;
    }
    .container-section-contactanos p {
        font-size: 20px;
    }
    .buttom-contactanos {
        height: 50px;
        width: 25%;
        margin-top: 0;
    }
    .buttom-contactanos h3 {
        font-size: 20px;
    }
    .background-contactanos {
        height: 200vw;
        background-size: 200%;
        margin-top: 50px;
        background-position: center top;
        background-size: 180vw
    }
}

@media only screen and (max-width: 600px) {
    .container-section-contactanos {
        top: 35vw;
    }
    .buttom-contactanos h3 {
        font-size: 15px;
    }
    .background-contactanos {
        height: 200vw;
        background-size: 200%;
        margin-top: 50px;
        background-position: center top;
        background-size: 200vw
    }
}

@media only screen and (max-width: 400px) {
    .container-section-contactanos {
        margin-top: 0%;
    }
    .container-section-contactanos h4 {
        font-size: 40px;
    }
    .container-section-contactanos p {
        font-size: 20px;
    }
    .buttom-contactanos h3 {
        font-size: 10px;
    }
    .background-contactanos {
        height: 200vw;
        background-size: 200%;
        margin-top: 50px;
        background-position: center top;
        background-size: 300vw
    }
}